import { makeVar, useReactiveVar } from "@apollo/client";
import { Location } from 'history';
import { getProbableInAppBrowser } from "../utils/in-app";


type LandingTrackingVar = {
  path: string | undefined;
  source: string | undefined;
  medium: string | undefined;
  campaign: string | undefined;
  content: string | undefined;
  linkBy: string | undefined;
  inviteTo: string | undefined;
  referrer: string | undefined;
  from: string | undefined;
  createdAt: Date | undefined;
  probableInAppBrowser: string | undefined;
  inAppToken: string | undefined;
};


export const landingTrackingVar = makeVar({} as LandingTrackingVar);


export const useLandingTrackingVar = () => {
  return useReactiveVar(landingTrackingVar);
};


export function setLandingTrackingVar(location: Location) {
  const searchParams = new URLSearchParams(location.search);

  const getFrom = (searchParams: URLSearchParams, documentReferrer?: string) => {
    if (searchParams.has('gclid')) {
      return 'google_ads';
    }
    if (searchParams.has('fbclid')) {
      return 'facebook_ads';
    }
    if (searchParams.has('twclid')) {
      return 'twitter_ads';
    }
    if (documentReferrer) {
      if(documentReferrer.includes('google')) {
        return 'google';
      }
      if(documentReferrer.includes('facebook')) {
        return 'facebook';
      }
      if(documentReferrer.includes('twitter') || documentReferrer.includes('://t.co')) {
        return 'twitter';
      }
    }
    if(searchParams.has('source')) {
      return searchParams.get('source');
    }
    if(searchParams.has('utm_source')) {
      return searchParams.get('utm_source');
    }
    if(documentReferrer) {
      return documentReferrer;
    }
    return null;
  };

  const before = landingTrackingVar();
  const after = {
    path: before.path || location.pathname,
    source: before.source || searchParams.get('source') || searchParams.get('utm_source') || undefined,
    medium: before.medium || searchParams.get('medium') || searchParams.get('utm_medium') || undefined,
    campaign: before.campaign || searchParams.get('campaign') || searchParams.get('utm_campaign') || undefined,
    content: before.content || searchParams.get('content') || searchParams.get('utm_content') || undefined,
    linkBy: before.linkBy || searchParams.get('link_by') || undefined,
    inviteTo: before.inviteTo || searchParams.get('invite_to') || undefined,
    referrer: before.referrer || window.document.referrer,
    from: before.from || getFrom(searchParams, window.document.referrer) || undefined,
    createdAt: before.createdAt || new Date(),
    probableInAppBrowser: getProbableInAppBrowser(window.navigator.userAgent) || undefined,
    inAppToken: before.inAppToken || searchParams.get('iat') || undefined,
  };

  // dedupe to prevent unnecessary tracking events
  if(JSON.stringify(before) !== JSON.stringify(after)) {
    landingTrackingVar(after);
  }
}


export function hasLandingTracking() {
  return Object.values(landingTrackingVar()).filter(Boolean).length > 0;
}
