
import { initializeApp } from "firebase/app"
import { initializeAuth, indexedDBLocalPersistence, signOut as fbSignOut, signInWithEmailAndPassword as fbSignInWithEmailAndPassword, UserCredential, browserLocalPersistence, browserPopupRedirectResolver } from 'firebase/auth';
import { getAnalytics, isSupported, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { cordovaPopupRedirectResolver } from 'firebase/auth/cordova';
import { Capacitor } from "@capacitor/core";


import { isServer } from "./utils/detect";


declare global {
  interface Window {
    gtag: Gtag.Gtag,
    dataLayer: object[],
    signInWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential>,
  }
}

const firebaseConfig = {
  apiKey: "AIzaSyCjph30AOhvhADzQHiWwzMG9wJ9Psw9JQA",
  authDomain: "app.icecream.club",
  databaseURL: "https://icecream-club.firebaseio.com",
  projectId: "icecream-club",
  storageBucket: "icecream-club.appspot.com",
  messagingSenderId: "424983210088",
  appId: "1:424983210088:web:a97f9036f701bd392673dd",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// disable automatic page view tracking since we handle that event ourselves
// https://github.com/firebase/firebase-js-sdk/issues/3988
if(!isServer) {
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line prefer-rest-params
  window.gtag = function gtag() { window.dataLayer.push(arguments); }
  window.gtag('set', { send_page_view: false });
}

const firebaseApp = initializeApp(firebaseConfig);

let firebaseAuth: ReturnType<typeof initializeAuth>;

if(process.env.BUILD_FOR_PLATFORM === 'app' && Capacitor.isNativePlatform()) {
  firebaseAuth = initializeAuth(firebaseApp, {
    persistence: indexedDBLocalPersistence,
    popupRedirectResolver: cordovaPopupRedirectResolver
  });
} else if(process.env.BUILD_FOR_PLATFORM !== 'ssr') {
  firebaseAuth = initializeAuth(firebaseApp, {
    persistence: [indexedDBLocalPersistence, browserLocalPersistence],
    popupRedirectResolver: browserPopupRedirectResolver,
  });
} else {
  // No real auth in SSR
  initializeAuth(firebaseApp, {
    persistence: [],
    popupRedirectResolver: undefined,
  });
}


let firebaseAnalytics = null;
const enableAnalytics = !!process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
if(!isServer) {
  isSupported().then(supported => {
    if(supported) {
      firebaseAnalytics = getAnalytics(firebaseApp);
      setAnalyticsCollectionEnabled(firebaseAnalytics, enableAnalytics);
    }
  });
}

export function signOut() {
  fbSignOut(firebaseAuth);
}

// Add emain/password sign in to window for cypress testing
if(!isServer) {
  window.signInWithEmailAndPassword = (email, password) => fbSignInWithEmailAndPassword(firebaseAuth, email, password);
}

export { firebaseApp, firebaseConfig, firebaseAuth };
export default firebaseApp;
