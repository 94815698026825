import { Redirect, useParams } from 'react-router-dom';
import { Error } from '../../components/Error';
import { Loading } from '../../components/Loading';
import { NotFound } from '../../components/NotFound';
import { useContact } from '../../hooks/use-contact';


export function ProfileRedirect() {
  const { contactId, tab, id } = useParams();

  const { data, loading, error } = useContact(contactId);

  if(error) {
    console.error("Couldn't resolve contact for ProfileRedirect", { id, error });
    return <Error error={error} />;
  }

  if(loading) {
    return <Loading />;
  }

  if(!data?.contact) {
    return <NotFound message={`Profile not found`} />;
  }

  let url = '/@' + data.contact.username;
  if(tab) {
    url += `/${tab}`;
    if(id) {
      url += `/${id}`;
    }
  }

  return <Redirect to={url} />;
}
