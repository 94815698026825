import { gql } from '@apollo/client';
import { FeedItemFragment } from './feedItemFragment';


export const GET_FEED_PAGE_SIZE = 12;

export const GET_FEED = gql`
  query GetFeed($first: Int, $after: String) {
    feed(first: $first, after: $after) {
      edges {
        node {
          ...FeedItemFragment
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${FeedItemFragment}
`;


export const GET_FEED_ITEM = gql`
  query GetFeedItem($shareId: ID, $feedItemId: ID) {
    getFeedItem(shareId: $shareId, feedItemId: $feedItemId) {
      ...FeedItemFragment
    }
  }
  ${FeedItemFragment}
`;


export const DELETE_FEED_ITEM = gql`
  mutation DeleteFeedItem($id: String!) {
    deleteFeedItem(id: $id) {
      status
    }
  }
`;


export const GET_PUBLIC_FEED = gql`
  query GetPublicFeed($first: Int, $after: String) {
    publicFeed(first: $first, after: $after) {
      edges {
        node {
          ...FeedItemFragment
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${FeedItemFragment}
`;


export const FEED_SUBSCRIPTION = gql`
  subscription FeedSubscription {
    feedUpdated {
      feedItemId
      createdAt
    }
  }
`;
