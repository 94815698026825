import { makeVar } from "@apollo/client";

type LoginPushVar = {
  loginHint: string;
  hasShown: boolean;
  displayState: 'hidden' | 'show' | 'initial';
};

export const loginPushVar = makeVar({
  loginHint: '',
  hasShown: false,
  displayState: 'initial',
} as LoginPushVar);
