import { gql } from '@apollo/client';
import { GroupFragment } from './groupFragment';
import { ContactFragment } from './contactFragment';
import { CanonicalFragment } from './canonicalFragment';


export const GET_USER_DATA = gql`
  query CurrentUser {
    currentUser {
      _id
      contact {
        ...ContactFragment
      }
      username
      name
      bio
      bioLink
      country
      language
      email
      emails
      allowMergeEmails
      token
      avatar {
        image {
          hash
        }
        url
      }
      contacts {
        ...ContactFragment
      }
      groups {
        ...GroupFragment
        members {
          _id
          accessLevel
          person {
            _id
          }
        }
      }
      unsubscribed {
        category
        deleted
      }
      channels {
        _id
        name
        channelId
      }
      firstInvitedBy {
        _id
      }
      firstGroup {
        _id
      }
      firstShare {
        _id
      }
      lastSeenAt
      activatedAt
      hasSeenPasswordAuth
      hasFirstReferrer
      twitter {
        user {
          username
        }
      }
      providers {
        _id
      }
      muted {
        _id
        share
        contact
      }
      blocked {
        _id
        share
        contact
      }
      businessAccounts {
        _id
        name
        username
        token
      }
    }
  }
  ${GroupFragment}
  ${ContactFragment}
`;


export const GET_USER_ACTIONS = gql`
  query CurrentUserActions {
    currentUser {
      _id
      actions {
        follows
        invites
        privateLists
        publicLists
        followedLists
        publicShares
        groupShares
        listShares
        listItems
        avatar
        createdGroups
        pushEnabled
      }
    }
  }
`;


export const GET_CONTACT = gql`
  query GetContact($id: ID, $username: String) {
    contact(id: $id, username: $username) {
      ...ContactFragment
    }
  }
  ${ContactFragment}
`;


export const GET_FOLLOWS = gql`
  query GetFollows {
    currentUser {
      _id
      follows {
        _id
      }
      followSuggestions {
        ...ContactFragment
      }
    }
  }
  ${ContactFragment}
`;


export const GET_WATCH_STATES = gql`
  query GetWatchStates($first: Int, $after: String) {
    currentUser {
      _id
      watched(first: $first, after: $after) {
        edges {
          cursor
          node {
            _id
            deleted
            canonicalContent {
              _id
            }
            share {
              _id
            }
            seasons {
              seasonNumber
              active
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
      watchlist(first: $first, after: $after) {
        edges {
          cursor
          node {
            _id
            deleted
            canonicalContent {
              _id
            }
            share {
              _id
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    }
  }
`;


export const USER_SUBSCRIPTION = gql`
  subscription UserSubscription {
    currentUserUpdated {
      type
      key
      payload
    }
  }
`;


export const ADD_CONTACT = gql`
  mutation AddContact($email: String!) {
    addContact(email: $email) {
      _id
    }
  }
`;


export const CREATE_INVITATION = gql`
  mutation CreateInvitation($name: String, $group: ID, $next: String, $from: String) {
    createInvitation(name: $name, group: $group, next: $next, from: $from) {
      contact {
        _id
      }
      invitationLink
    }
  }
`;


export const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation($input: AcceptInvitationInput!) {
    acceptInvitation(input: $input) {
      firebaseCustomToken
    }
  }
`;

export const ADD_PUSH_SUBSCRIPTION = gql`
  mutation AddPushSubscription($type: String!, $channelId: String!, $payload: JSON!) {
    addPushSubscription(type: $type, channelId: $channelId, payload: $payload) {
      _id
    }
  }
`;

export const REMOVE_PUSH_SUBSCRIPTION = gql`
  mutation RemovePushSubscription($channelId: String!) {
    removePushSubscription(channelId: $channelId) {
      _id
    }
  }
`;

export const UNSUBSCRIBE_FROM_EMAIL = gql`
  mutation unsubscribeFromEmail($category: String!, $deleted: Boolean!) {
    unsubscribeFromEmail(category: $category, deleted: $deleted) {
      _id
      unsubscribed {
        category
        deleted
      }
    }
  }
`

export const ADD_EMAIL = gql`
  mutation AddEmail($email: String!, $primary: Boolean) {
    addEmail(email: $email, primary: $primary) {
      _id
      email
      emails
      allowMergeEmails
    }
  }
`;

export const DELETE_CURRENT_USER = gql`
  mutation DeleteCurrentUser($person: Boolean!, $firebase: Boolean!) {
    deleteCurrentUser(person: $person, firebase: $firebase) {
      status
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      _id
      username
      name
      bio
      bioLink
      country
      email
      emails
      allowMergeEmails
      avatar {
        image {
          hash
        }
        url
      }
      lastSeenAt
      hasSeenPasswordAuth
      hasFirstReferrer
    }
  }
`;


export const ADD_ONBOARDING = gql`
  mutation AddOnboarding($input: OnboardingInput!) {
    addOnboarding(input: $input) {
      _id
      onboarding {
        key
        value
        createdAt
        appVersion
        devicePlatform
        deviceId
        onboardingVersion
      }
    }
  }
`;


export const GET_ONBOARDING = gql`
  query GetOnboarding {
    currentUser {
      _id
      onboarding {
        key
        value
        createdAt
        appVersion
        devicePlatform
        deviceId
        onboardingVersion
      }
    }
  }
`;


export const UPDATE_WATCH_STATE = gql`
  mutation UpdateWatchState($input: WatchStateInput!) {
    updateWatchState(input: $input) {
      _id
      deleted
      createdAt
      share {
        _id
      }
      canonicalContent {
        _id
      }
      seasons {
        seasonNumber
        active
      }
    }
  }
`;


export const UPDATE_DEVICE_CONTACTS = gql`
  mutation UpdateDeviceContacts($input: UpdateDeviceContactsInput!) {
    updateDeviceContacts(input: $input) {
      status
    }
  }
`;


export const SELECT_PROVIDER = gql`
  mutation SelectProvider($input: SelectProviderInput!) {
    selectProvider(input: $input) {
      _id
      providers {
        _id
      }
    }
  }
`;


export const GET_OTHERS_WATCHLIST = gql`
  query GetOthersWatchlist {
    othersWatchlist {
      ...CanonicalFragment
      watchState {
        watchedBy
        watchlistBy
      }
    }
  }
  ${CanonicalFragment}
`;


export const UPDATE_RATING = gql`
  mutation UpdateRating($input: UpdateRatingInput!) {
    updateRating(input: $input) {
      share {
        _id
        rating {
          _id
          rating
          person {
            _id
          }
        }
        sendersRating {
          _id
          rating
          person {
            _id
          }
        }
      }
      canonicalContent {
        _id
        rating {
          _id
          rating
          person {
            _id
          }
        }
      }
    }
  }
`;


export const TEST_CLEANUP = gql`
  mutation TestCleanup($email: String!) {
    testCleanup(email: $email) {
      status
    }
  }
`;
