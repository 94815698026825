import { gql } from '@apollo/client';


export const NOTIFICATIONS = gql`
  query Notifications {
    notifications {
      id
      sender {
        _id
      }
      message
      link
      type
      status
      createdAt
    }
  }
`;


export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      id
      sender {
        _id
      }
      message
      link
      type
      status
      createdAt
    }
  }
`;


export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription NotificationUpdated {
    notificationUpdated {
      id
      sender {
        _id
      }
      message
      link
      type
      status
      createdAt
    }
  }
`;


export const MUTE_NOTIFICATIONS = gql`
  mutation MuteNotifications($input: MuteNotificationsInput!) {
    muteNotifications(input: $input) {
      _id
      muted {
        _id
        contact
        share
      }
    }
  }
`;
