import { Box, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { useSession } from '../../hooks/auth';
import { Notifications } from '../user/Notifications';
import { BottomSheet } from '../../components/BottomSheet';
import { Button } from '../../components/Button';


export function Unsubscribe() {

  const history = useHistory();
  const { user } = useSession();

  return (
    <BottomSheet onClose={() => history.push('/feed')} onOpen={() => {}} variant='modal'>
      <Typography variant='h3' sx={{ my: 2 }} align='center'>
        Unsubscribe from email
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Notifications user={user} />
      </Box>

      <Box sx={{ mt: 6 }}>
        Help us improve the app for you and your friends by telling us why you unsubscribed. Thanks!
      </Box>
      <Button
        component='a'
        href='mailto:feedback@icecream.club'
        sx={{ mt: 2 }}
        variant='contained'
        fullWidth
      >
        Give feedback
      </Button>
    </BottomSheet>
  );
}

Unsubscribe.propTypes = {
}
