import { alpha, createTheme as createMuiTheme } from '@mui/material/styles';
import { grey } from "@mui/material/colors";
import Poppins100 from './fonts/poppins/poppins-v15-latin-ext_latin_devanagari-100.woff2';
import Poppins400 from './fonts/poppins/poppins-v15-latin-ext_latin_devanagari-regular.woff2';
import Poppins500 from './fonts/poppins/poppins-v15-latin-ext_latin_devanagari-500.woff2';
import Poppins700 from './fonts/poppins/poppins-v15-latin-ext_latin_devanagari-700.woff2';

const poppins = [
  {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 100,
    fontDisplay: 'swap',
    src: `url(${Poppins100}) format('woff2')`
  },
  {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontDisplay: 'swap',
    src: `url(${Poppins400}) format('woff2')`
  },
  {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontDisplay: 'swap',
    src: `url(${Poppins500}) format('woff2')`
  },
  {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontDisplay: 'swap',
    src: `url(${Poppins700}) format('woff2')`
  },
];


const light = {
  typography: {
    fontSize: 13,
    fontWeightMedium: 500,
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      textTransform: 'none',
      fontWeight: 500,
      fontSize: 16,
    },
    h1: {
      fontWeight: 500,
      fontSize: 32,
      lineHeight: 'normal'
    },
    h2: {
      fontWeight: 500,
      fontSize: 22,
    },
    h3: {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: 'normal'
    },
    h4: {
      fontWeight: 500,
      fontSize: 16,
    },
    h5: {
      fontWeight: 700,
      fontSize: 12,
      textTransform: 'uppercase',
    },
    h6: {
      fontWeight: 700,
      fontSize: 12,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: 12,
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
    },
    body2: {
      fontWeight: 400,
      fontSize: 14,
    },
    link: {
      fontWeight: 500,
      fontSize: 16,
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#FE2F77',
      dark: '#FE2F77',
      light: '#FE94B9',
      background: '#FFF0F5',
      lighter: '#FFF0F5',
      contrastText: '#fff',
      darkContrastText: '#fff',
    },
    primaryInverted: {
      main: '#fff',
      dark: '#fff',
      light: '#fff',
      lighter: '#fff',
      background: '#FE2F77',
      contrastText: '#FE2F77',
    },
    secondary: {
      main: '#3E3FFB',
      dark: '#3E3FFB',
      light: '#7879FC',
      background: '#E6E6FE',
      lighter: '#E6E6FE',
      contrastText: '#FFF',
      darkContrastText: '#FFF',
    },
    secondaryInverted: {
      main: '#fff',
      contrastText: '#3E3FFB',
    },
    accent: {},
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)'
    },
    offWhite: {
      main: 'rgba(245, 245, 245, 1)',
      dark: 'rgba(245, 245, 245, 1)',
      light: 'rgba(250, 250, 250, 1)',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    grey: {
      main: grey[200],
      dark: grey[300]
    },
    groups: {
      blue: { // blueberry
        main: '#7BC0E5',
        dark: '#2A99D5',
        light: '#B2DAF0',
        background: '#EAF5FB',
        contrastText: '#000',
        darkContrastText: '#FFF',
      },
      purple: { // raspberry
        main: '#D1B1CB',
        dark: '#AA6F9F',
        light: '#E1CCDD',
        background: '#F6EFF4',
        contrastText: '#000',
        darkContrastText: '#FFF',
      },
      red: { // strawberry
        main: '#E2887C',
        dark: '#D55140',
        light: '#EBACA3',
        background: '#FBF0EE',
        contrastText: '#000',
        darkContrastText: '#FFF',
      },
      brown: { // chocolate
        main: '#C9AB9C',
        dark: '#A5735A',
        light: '#E4D5CD',
        background: '#F2EBE8',
        contrastText: '#000',
        darkContrastText: '#FFF',
      },
      yellow: { // lemon
        main: '#F3DD6D',
        dark: '#EFD139',
        light: '#F8EAA5',
        background: '#FDFAE7',
        contrastText: '#000',
        darkContrastText: '#000',
      },
      green: { // pistachio
        main: '#ACCE92',
        dark: '#76AD4D',
        light: '#CFE3BF',
        background: '#F2F7ED',
        contrastText: '#000',
        darkContrastText: '#FFF',
      },
      orange: { // orange
        main: '#FFBF75',
        dark: '#FF8B07',
        light: '#FFDBB3',
        background: '#FFF5EB',
        contrastText: '#000',
        darkContrastText: '#FFF',
      },
      public: {
        main: '#3E3FFB',
        dark: '#3E3FFB',
        light: '#7879FC',
        background: '#E6E6FE',
        lighter: '#E6E6FE',
        contrastText: '#FFF',
        darkContrastText: '#FFF',
      },
      direct: {
        main: '#FE2F77',
        dark: '#FE2F77',
        light: '#FE94B9',
        background: '#FFF0F5',
        lighter: '#FFF0F5',
        contrastText: '#fff',
        darkContrastText: '#fff',
      },
    },
    error: {
      main: 'rgba(238, 66, 102, 1)'
    }
  },
  shadows: [
    '0px 0px 0px 0px rgba(0, 0, 0, 0)',
    '0px 2px 2px 0px rgba(0, 0, 0, 0.1)',
    '0px 4px 4px 0px rgba(0, 0, 0, 0.1)',
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '',
    '',
    '',
    'rgba(255,255,255,0.5) 0px 3px 3px', // emboss textShadow
    '0px 1px 3px 0px rgb(0 0 0 / 15%)', // DepressButton up
    'inset 0px 2px 5px 0px rgb(0 0 0 / 10%), 0px 1px 2px 0px rgb(0 0 0 / 10%)', // DepressButton down
    "inset 0px 12px 8px -10px #CCC, inset 0px -12px 8px -10px #CCC, 0 0 8px 8px #CCC", // autocomplete overlay
    ...new Array(23).fill('')
  ],
  zIndex: {
    videoPlayer: 1350,
    bottomNav: 100,
    modal: 1300,
  },
  other: {
    feedBorder: '1px solid rgba(238, 238, 238, 1)'
  },
  shape: {
    borderRadius: 8,
  },
};


const dark = {
  ...light,
  palette: {
    ...light.palette,
    mode: 'dark',
    text: {
      primary: '#fff',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
    grey: {
      '50': grey[900],
      '100': grey[900],
      '200': grey[800],
      '300': grey[700],
      '400': grey[600],
      '500': grey[500],
      '600': grey[400],
      '700': grey[300],
      '800': grey[200],
      '900': grey[100],
      main: grey[800],
      dark: grey[700],
    },
    groups: {
      ...light.palette.groups,
      blue: { // blueberry
        ...light.palette.groups.blue,
        light: light.palette.groups.blue.dark,
        dark: light.palette.groups.blue.light,
        background: '#09222f',
      },
      purple: { // raspberry
        ...light.palette.groups.purple,
        light: light.palette.groups.purple.dark,
        dark: light.palette.groups.purple.light,
        background: '#20131e',
      },
      red: { // strawberry
        ...light.palette.groups.red,
        light: light.palette.groups.red.dark,
        dark: light.palette.groups.red.light,
        background: '#3b120d',
      },
      brown: { // chocolate
        ...light.palette.groups.brown,
        light: light.palette.groups.brown.dark,
        dark: light.palette.groups.brown.light,
        background: '#211712',
      },
      yellow: { // lemon
        ...light.palette.groups.yellow,
        light: light.palette.groups.yellow.dark,
        dark: light.palette.groups.yellow.light,
        background: '#2a2403',
      },
      green: { // pistachio
        ...light.palette.groups.green,
        light: light.palette.groups.green.dark,
        dark: light.palette.groups.green.light,
        background: '#182310',
      },
      orange: { // orange
        ...light.palette.groups.orange,
        light: light.palette.groups.orange.dark,
        dark: light.palette.groups.orange.light,
        background: '#472600',
      },
      public: {
        ...light.palette.groups.public,
        light: light.palette.groups.public.dark,
        dark: light.palette.groups.public.light,
        background: '#010137',
      },
      direct: {
        ...light.palette.groups.direct,
        light: light.palette.groups.direct.dark,
        dark: light.palette.groups.direct.light,
        background: '#610032',
      },
    },
    offWhite: {
      main: '#222',
      dark: '#222',
      light: '#111',
      contrastText: 'rgba(255, 255, 255, 0.9)',
    },
    primary: {
      main: '#FE2F77',
      dark: '#FE2F77',
      light: '#FE94B9',
      background: '#610032',
      lighter: '#ffbad0',
      contrastText: '#222',
      darkContrastText: '#000',
    },
    primaryInverted: {
      main: '#000',
      dark: '#000',
      light: '#000',
      lighter: '#000',
      background: '#FE2F77',
      contrastText: '#FE2F77',
    },
    secondary: {
      main: '#3E3FFB',
      dark: '#3E3FFB',
      light: '#7879FC',
      background: '#010137',
      lighter: '#E6E6FE',
      contrastText: '#222',
      darkContrastText: '#000',
    },
    secondaryInverted: {
      main: '#000',
      contrastText: '#3E3FFB',
    },
    accent: {},
    error: {
      main: 'rgba(238, 66, 102, 1)'
    },
  },
  shadows: [
    '0px 0px 0px 0px rgba(0, 0, 0, 0)',
    '0px 2px 2px 0px rgba(0, 0, 0, 0.1)',
    '0px 4px 4px 0px rgba(0, 0, 0, 0.1)',
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '',
    '',
    '',
    'rgba(0,0,0,0.5) 0px 3px 3px', // emboss textShadow
    '0px 1px 3px 0px rgb(0 0 0 / 15%)', // DepressButton up
    'inset 0px 2px 5px 0px rgb(0 0 0 / 10%), 0px 1px 2px 0px rgb(0 0 0 / 10%)', // DepressButton down
    "inset 0px 12px 8px -10px #333, inset 0px -12px 8px -10px #333, 0 0 8px 8px #333", // autocomplete overlay
    ...new Array(23).fill('')
  ],
  other: {
    ...light.other,
    feedBorder: '2px solid rgba(255, 255, 255, 0.23)'
  },
};


const inputComponents = (theme) => ({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 200],
          color: theme.palette.text.primary,
        },
        input: {
          fontSize: '16px',
          '&::placeholder': {
            fontSize: '16px',
            color: theme.palette.grey[800],
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "16px",
        },
        filled: {
          '&$sizeSmall': {
            transform: 'translate(12px, 11px) scale(1)',
            '&$shrink': {
              transform: 'translate(12px, -10px) scale(0.9)',
            }
          },
        },
        shrink: {
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 200],
          '&:hover': {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 200],
          },
          '&$focused': {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 200],
          }
        },
        input: {
          ...theme.typography.body1,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          '&.Mui-focused': {
            backgroundColor: alpha(theme.palette.grey[900], 0.09),
          },
        },
        inputSizeSmall: {
          paddingTop: 12,
          paddingBottom: 12,
        },
        multiline: {
          '&$sizeSmall': {
            paddingTop: 12,
            paddingBottom: 12,
          }
        },
        underline: {
          "&&&:before": {
            borderBottom: "none"
          },
          "&&:after": {
            borderBottom: "none"
          }
        },
        input: {
          fontSize: '16px',
        },
      }
    },
  }
});


const greyButtonVariant = (theme) => ({
  components: {
    MuiButton: {
      variants: [
        {
          props: { size: 'xsmall' },
          style: {
            padding: '3px 6px',
            fontSize: '14px',
            fontWeight: 'normal',
            borderRadius: '4px',
            '& .MuiButton-startIcon': {
              marginRight: '4px',
            }
          }
        },
        {
          props: { variant: "contained", color: "grey" },
          style: {
            color: theme.palette.getContrastText(theme.palette.grey[300])
          }
        },
        {
          props: { variant: "outlined", color: "grey" },
          style: {
            color: theme.palette.text.primary,
            borderColor:
              theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.23)"
                : "rgba(255, 255, 255, 0.23)",
            "&.Mui-disabled": {
              border: `1px solid ${theme.palette.action.disabledBackground}`
            },
            "&:hover": {
              borderColor:
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.23)"
                  : "rgba(255, 255, 255, 0.23)",
              backgroundColor: alpha(
                theme.palette.text.primary,
                theme.palette.action.hoverOpacity
              )
            }
          }
        },
        {
          props: { color: "grey", variant: "text" },
          style: {
            color: theme.palette.text.primary,
            "&:hover": {
              backgroundColor: alpha(
                theme.palette.text.primary,
                theme.palette.action.hoverOpacity
              )
            }
          }
        }
      ]
    }
  }
});


const components = () => ({
  components: {
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        '@font-face /* 100 */': poppins[0],
        '@font-face /* 400 */': poppins[1],
        '@font-face /* 500 */': poppins[2],
        '@font-face /* 700 */': poppins[3],
        body: {
          'backgroundColor': theme.palette.background.paper, // 'rgba(255, 255, 255, 0)',
          'color': theme.palette.text.primary,
        }
      })
    },
    MuiButton: {
      defaultProps: {
        disableElevation: false,
      },
      styleOverrides: {
        root: {
          fontSize: '16px',
          borderWidth: '2px',
          borderRadius: '4px',
          '$outlinedSizeSmall': {
            borderWidth: '2px',
          },
          '&:hover': {
            borderWidth: '2px',
          }
        },
        contained: {
          boxShadow: '0px 2px 2px 0px rgb(0, 0, 0, 0.1)',
        }
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      }
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          '&&:hover': {
            boxShadow: '0px 2px 2px 0px rgb(0, 0, 0, 0.1)'
          }
        }
      }
    },
    MuiTab: {
      disableRipple: true
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          boxShadow: 'none',
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'inherit',
          '&.Mui-focused': {
            color: 'inherit'
          }
        },
        focused: {}
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        dense: {
          margin: 0,
        }
      }
    }
  }
});


function createTheme(mode) {
  let theme = null;
  if(mode === 'dark') {
    theme = createMuiTheme(dark);
  } else {
    theme = createMuiTheme(light);
  }
  theme = createMuiTheme(theme, greyButtonVariant(theme), inputComponents(theme), components(theme));
  return theme;
}


export { light, dark, createTheme };
