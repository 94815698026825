import { InMemoryCache } from '@apollo/client/cache';
import { relayStylePagination } from "@apollo/client/utilities";


function mergeArrayById(existing, incoming, { readField, mergeObjects }) {
  const merged = existing ? existing.slice(0) : [];
  const idToIndex = {};
  if (existing) {
    existing.forEach((el, index) => {
      idToIndex[readField("_id", el)] = index;
    });
  }
  incoming.forEach(el => {
    const id = readField("_id", el);
    const index = idToIndex[id];
    if (typeof index === "number") {
      // Merge the new data with the existing data.
      merged[index] = mergeObjects(merged[index], el);
    } else {
      // First time we've seen this in this array.
      idToIndex[id] = merged.length;
      merged.push(el);
    }
  });
  return merged;
}


export function createCache() {

  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          currentUser: {
            merge: true,
          },
          feed: relayStylePagination(),
          publicFeed: relayStylePagination(),
          personalExploreShares: relayStylePagination(),
          discoverFeed: relayStylePagination(),
          getListInbox: relayStylePagination(),
          friendsActivity: relayStylePagination(['filter']),
          contact: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'Contact',
                _id: args.id,
              });
            }
          },
          getShare: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'Share',
                _id: args.id,
              });
            }
          },
          getCanonical: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'CanonicalContent',
                _id: args.canonicalId,
              });
            }
          },
          profile: {
            merge: true,
          },
        },
      },
      ChatTopic: {
        fields: {
          messages: relayStylePagination(),
        }
      },
      Group: {
        fields: {
          feed: relayStylePagination(),
        }
      },
      FeedItem: {
        fields: {
          lists: {
            merge: false,
          }
        }
      },
      List: {
        fields: {
          items: {
            merge: false,
          },
          listItems: relayStylePagination(),
        }
      },
      Person: {
        fields: {
          channels: {
            merge: false,
          },
          contacts: {
            merge: false,
          },
          follows: {
            merge: false,
          },
          followSuggestions: {
            merge: mergeArrayById,
          },
          lists:{
            merge: false,
          },
          followedLists: {
            merge: false,
          },
          groups: {
            merge: false,
          },
          providers: {
            merge: false,
          },
          watched: relayStylePagination(["first", "last"]),
          watchlist: relayStylePagination(["first", "last"]),
          group: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'Group',
                _id: args._id,
              });
            }
          },
          muted: {
            merge: false,
          },
        }
      },
      Profile: {
        fields: {
          watched: relayStylePagination(["first", "last"]),
          watchlist: relayStylePagination(["first", "last"]),
        }
      },
      Share: {
        fields: {
          reactions: {
            merge: false
          },
          message: {
            merge: true
          },
          canonicalContent: {
            merge: true
          },
          comments: {
            merge: false
          },
          context: {
            merge: true
          },
        }
      }
    },
  });

}
