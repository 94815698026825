import { gql } from '@apollo/client';

export const VERIFY_TOKEN = gql`
  mutation VerifyToken($token: String!) {
    verifyToken(token: $token) {
      firebaseCustomToken
    }
  }
`;

export const REQUEST_LOGIN_EMAIL = gql`
  mutation RequestLoginEmail($email: String!, $platform: String) {
    requestLoginEmail(email: $email, platform: $platform) {
      status
    }
  }
`;
