import { gql } from "@apollo/client";
import { GroupFragment } from "./groupFragment";
import { ListFragment } from "./listFragment";
import { CommentFragment } from "./commentFragment";


export const ShareFragment = gql`
  fragment ShareFragment on Share {
    _id
    createdAt
    deleted
    sender {
      _id
    }
    message {
      link
      shortLink
      text
      language
      image {
        hash
        blurhash
        width
        height
        alt
      }
    }
    canonicalContent {
      _id
    }
    list {
      ...ListFragment
    }
    lists
    reshares
    reshareOf {
      _id
      sender {
        _id
      }
      message {
        text
        language
        link
      }
      commentCount
    }
    mentions {
      callout
      person {
        _id
      }
    }
    textLinks {
      callout
      unfurl {
        title
        link
        author
      }
    }
    unfurl {
      title
      link
      description
      author
      type
      images {
        hash
        blurhash
        width
        height
        alt
      }
    }
    context {
      type
      group {
        ...GroupFragment
      }
      people {
        _id
      }
    }
    commentCount
    reactions {
      emoji {
        id
        name
        colons
        skin
        native
      }
      reacted {
        _id
      }
    }
    rating {
      _id
      rating
      person {
        _id
      }
    }
    sendersRating {
      _id
      rating
      person {
        _id
      }
    }
  }
  ${GroupFragment}
  ${ListFragment}
`;


export const ShareCommentsFragment = gql`
  fragment ShareCommentsFragment on Share {
    comments {
      ...CommentFragment
    }
  }
  ${CommentFragment}
`;
