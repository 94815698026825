// import './wdyr';
import { StrictMode } from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CacheProvider } from '@emotion/react';
import { HelmetProvider } from 'react-helmet-async';
import { loadableReady } from '@loadable/component'
import '@ungap/global-this';

import * as serviceWorkerLoader from '../service-worker-loader';
import '../detect-keyboard';
import '../utils/sentry';

import '../utils/i18n';
import Providers from '../Providers';
import App from '../App';
import { createEmotionCache } from '../createEmotionCache';
import { getClient } from './client';


const ready = process.env.BUILD_FOR_PLATFORM === 'app' ? (f) => f() : loadableReady;


(async () => {
  const client = await getClient();
  ready(() => {
    const cache = createEmotionCache();
    const container = document.getElementById('root');

    const ReactTree = (
      <BrowserRouter>
        <StrictMode>
          <CacheProvider value={cache}>
            <HelmetProvider>
              <Providers client={client}>
                <App />
              </Providers>
            </HelmetProvider>
          </CacheProvider>
        </StrictMode>
      </BrowserRouter>
    );

    if (container && container.innerHTML !== "") {
      console.log('hydrating react tree');
      hydrateRoot(
        container,
        ReactTree
      );
    } else {
      console.log('creating react tree');
      const root = createRoot(container);
      root.render(ReactTree);
    }
  });
})();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerLoader.register();
