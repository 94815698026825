import { gql } from '@apollo/client';


export const AdFragment = gql`
  fragment AdFragment on Ad {
    _id
    type
    account {
      _id
      username
      name
      color
      avatar {
        image {
          hash
        }
        url
      }
    }
    activeFrom
    activeTo
    link
    color
    campaign
    title
    body
    html
    buttonText
    image {
      hash
      width
      height
    }
    externalImage
    externalVideo
    trackingPixel
  }
`;
