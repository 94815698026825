import { makeVar } from "@apollo/client";
import type { Location } from "history";


export type LocationMeta = {
  location?: Location;
  action?: string;
  timestamp?: number;
};

export const locationWithMetaVar = makeVar({
  location: undefined,
  action: undefined,
  timestamp: undefined
} as LocationMeta);
