import { Video } from "../generated/graphql";

export function createVideoUrl(video: Pick<Video, 'key' | 'site'>): string {
  if(video?.site === 'YouTube') {
    return `https://www.youtube.com/watch?v=${video.key}`;
  } else if(video?.site === 'Vimeo') {
    return `https://vimeo.com/${video.key}`;
  }
  return '';
}


export function createVideoThumbnailUrl(video: Pick<Video, 'key' | 'site'>): string {
  if(video?.site === 'YouTube') {
    return `https://img.youtube.com/vi/${video.key}/maxresdefault.jpg`
  }
  return '';
}
