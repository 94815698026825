import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { AnalyticsProvider } from 'use-analytics';

import analytics from './analytics';
import { AuthProvider } from './hooks/auth';
import { DataProvider } from './hooks';
import { HistoryStackProvider } from './hooks/history';
import { VideoPlayerProvider } from './hooks/video-player';


export default function Providers({ children, client }) {

  return (
    <ApolloProvider client={client}>
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        <AnalyticsProvider instance={analytics}>
          <HistoryStackProvider>
            <AuthProvider>
              <DataProvider>
                <VideoPlayerProvider>
                  {children}
                </VideoPlayerProvider>
              </DataProvider>
            </AuthProvider>
          </HistoryStackProvider>
        </AnalyticsProvider>
      </QueryParamProvider>
    </ApolloProvider>
  );
}

Providers.propTypes = {
  children: PropTypes.any.isRequired,
  client: PropTypes.any.isRequired,
};
