import { gql } from '@apollo/client';


export const GroupFragment = gql`
  fragment GroupFragment on Group {
    _id
    name
    color
  }
`;
