import "urlpattern-polyfill";


export function validateUrl(url: string): boolean {
  const urlRegexStr = '^(?:(?:http|https)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
  const urlRegex = new RegExp(urlRegexStr, 'i');
  return !!url && !!url.match(urlRegex);
}


export function isRelativeUrl(url: string): boolean {
  /* eslint-disable no-useless-escape */
  const relativeUrlRegex = new RegExp(
    '^\\/(?=[^/]|$)'+
    '([-a-z\\d%@_.~+]*\\/?)*'+ // path
    '(\\?[;&a-z\\d%_.~+=!()<>/-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i' // fragment locator/i
  );
  /* eslint-enable no-useless-escape */
  return relativeUrlRegex.test(url);
}

export function extractUrls(text: string): string[] {
  const urlRegexStr = '(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(?:/|\\?|#)[^\\s]*)?';
  const urlRegex = new RegExp(urlRegexStr, 'i');
  const links = text.match(urlRegex);
  return links?.filter(Boolean) || [];
}

export function domain(link: string): string {
  if(!link) return '';
  try {
    const url = new URL(link);
    const hostname = url.hostname;
    return hostname.replace(/^www\./, '');
  } catch(e) {
    console.warn('error parsing url for domain', link, e);
  }
  return link;
}


export function relativize(link: string): string {
  const origin = typeof window !== 'undefined' ? window.location.origin : 'https://app.icecream.club';
  const url = new URL(link, origin);
  return url.toString().substring(url.origin.length);
}

export function absolutize(link: string, base = 'https://app.icecream.club'): string {
  const url = new URL(link, base);
  return url.toString();
}

export const iccPattern = new URLPattern({ hostname: '(app.icecream.club|localhost:8080|localhost)' });
export function testIccLink(url: string): boolean {
  return iccPattern.test(url ? url.toString() : '');
}

const collectionPattern = new URLPattern({ hostname: '(app.icecream.club|localhost:8080|localhost)', pathname: '/@:username/collections/:collectionId{/}?' });
export function testIccCollection(url: string): boolean {
  return collectionPattern.test(url ? url.toString() : '');
}

const canonicalPattern = new URLPattern({ hostname: '(app.icecream.club|localhost:8080|localhost)', pathname: '/canonical/:canonicalId{/:shareId}?{/}?' });
export function testIccCanonical(url: string): boolean {
  return canonicalPattern.test(url ? url.toString() : '');
}

const postPattern = new URLPattern({ hostname: '(app.icecream.club|localhost:8080|localhost)', pathname: '/post/:shareId{/canonical}?{/}?' });
export function testIccPost(url: string): boolean {
  return postPattern.test(url ? url.toString() : '');
}

const profilePattern = new URLPattern({ hostname: '(app.icecream.club|localhost:8080|localhost)', pathname: '/@:username/:tab(collections)?{/}?' });
export function testIccProfile(url: string): boolean {
  return profilePattern.test(url ? url.toString() : '');
}

export function testHasSameContent(url: string, currentLocation: string): boolean {
  const urlParsed = parseInternal(url);
  const currentLocationParsed = parseInternal(currentLocation);
  if(urlParsed.isInternal && currentLocationParsed.isInternal) {
    if(urlParsed.isCanonical && currentLocationParsed.isCanonical) {
      const canonical1 = canonicalPattern.exec(url) as URLPatternResult;
      const canonical2 = canonicalPattern.exec(currentLocation) as URLPatternResult;
      if(canonical1.pathname.groups.canonicalId === canonical2.pathname.groups.canonicalId) {
        if(canonical1.pathname.groups.shareId === canonical2.pathname.groups.shareId) {
          return true;
        } else if(!canonical1.pathname.groups.shareId && canonical2.pathname.groups.shareId) {
          return true;
        }
      }
    }
    if(urlParsed.isPost && currentLocationParsed.isPost) {
      const post1 = postPattern.exec(url) as URLPatternResult;
      const post2 = postPattern.exec(currentLocation) as URLPatternResult;
      return post1.pathname.groups.shareId === post2.pathname.groups.shareId;
    }
    if(urlParsed.isPost && currentLocationParsed.isCanonical) {
      const post1 = postPattern.exec(url) as URLPatternResult;
      const canonical2 = canonicalPattern.exec(currentLocation) as URLPatternResult;
      return post1.pathname.groups.shareId === canonical2.pathname.groups.shareId;
    }
    if(urlParsed.isCanonical && currentLocationParsed.isPost) {
      const canonical1 = canonicalPattern.exec(url) as URLPatternResult;
      const post2 = postPattern.exec(currentLocation) as URLPatternResult;
      return canonical1.pathname.groups.shareId === post2.pathname.groups.shareId;
    }
    if(urlParsed.isProfile && currentLocationParsed.isProfile) {
      const profile1 = profilePattern.exec(url) as URLPatternResult;
      const profile2 = profilePattern.exec(currentLocation) as URLPatternResult;
      return profile1.pathname.groups.username === profile2.pathname.groups.username;
    }
    if(urlParsed.isCollection && currentLocationParsed.isCollection) {
      const collection1 = collectionPattern.exec(url) as URLPatternResult;
      const collection2 = collectionPattern.exec(currentLocation) as URLPatternResult;
      return collection1.pathname.groups.username === collection2.pathname.groups.username && collection1.pathname.groups.collectionId === collection2.pathname.groups.collectionId;
    }
  }
  return url === currentLocation;
}

export function parseInternal(url: string) {
  return {
    isInternal: testIccLink(url),
    isCollection: testIccCollection(url),
    isCanonical: testIccCanonical(url),
    isPost: testIccPost(url),
    isProfile: testIccProfile(url),
  }
}


export function testYoutube(url: string): boolean {
  if(/^https?:\/\/www.youtube.com\/watch\?v=[a-z0-9-]+/i.test(url)) {
    return true;
  }
  if(/^https?:\/\/youtu.be\/[a-z0-9-]+/i.test(url)) {
    return true;
  }
  return false;
}
