import i18next from "i18next";
import { useTranslation, initReactI18next, Trans } from "react-i18next";

import BrowserLanguageDetector from 'i18next-browser-languagedetector';

import { isServer } from "./detect";


// global translations
const resources = {};

let i18n = i18next;
let i18nextMiddleware = null;

if(isServer) {
  const expressMiddleware = require('i18next-http-middleware');
  i18n = i18n.use(expressMiddleware.LanguageDetector).use(initReactI18next);
  i18nextMiddleware = expressMiddleware.handle(i18next, {
    ignoreRoutes: [],
    removeLngFromUrl: false
  });
} else {
  i18n = i18n.use(BrowserLanguageDetector).use(initReactI18next);
}

i18n.init({
  resources,
  fallbackLng: "en",
  supportedLngs: ['en', 'sv', 'sv-SE'],
  debug: process.env.NODE_ENV === 'development',

  interpolation: {
    escapeValue: false // react already safes from xss
  }
});


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loadLanguageBundle = (namespace: string, language: Record<string, any>) => {

  for (const lang of Object.keys(language) as Array<keyof typeof language>) {
    i18next.addResourceBundle(lang, 'auth', language[lang]);
  }

}


export { i18n, i18nextMiddleware, useTranslation, Trans };
export default i18n;
