

export const filter = (raw: object, properties: string[]): object => {
  return Object.fromEntries(Object.entries(raw).filter(([key]) => properties.includes(key)));
}


export const removeEmpty = (obj: object): object => {
  return Object.entries(obj)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
}


export const isEmpty = (obj: object): boolean => {
  // because Object.keys(new Date()).length === 0;
  // we have to do some additional check
  return obj // 👈 null and undefined check
    && Object.keys(obj).length === 0
    && Object.getPrototypeOf(obj) === Object.prototype;
}
