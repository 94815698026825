import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";

export function AcceptInvitationRedirect() {
  const history = useHistory();
  const [ { next, key } ] = useQueryParams({ next: StringParam, key: StringParam });

  useEffect(() => {
    if(next && key) {
      const nextUrl = new URL(next, window.location.origin);
      nextUrl.searchParams.append('invitation_token', key);
      history.replace(`${nextUrl.pathname}?${nextUrl.searchParams}`);
    }
  }, [next, key, history]);
  return null;
}
AcceptInvitationRedirect.propTypes = {
};
