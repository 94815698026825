import { gql } from '@apollo/client';


export const CommentFragment = gql`
  fragment CommentFragment on Comment {
    _id
    author {
      _id
    }
    text
    language
    mentions {
      callout
      person {
        _id
      }
    }
    textLinks {
      callout
      unfurl {
        title
        link
        author
      }
    }
    reactions {
      emoji {
        id
        name
        colons
        skin
        native
      }
      reacted {
        _id
      }
    }
    createdAt
  }
`;
