import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';

const isAvailable = Capacitor.isPluginAvailable('Keyboard')

if(isAvailable) {
  Keyboard.addListener('keyboardWillShow', () => {
    document.body.classList.add('keyboard-open');
  }).catch(err => {
    console.error('Keyboard.addListener error', err);
  });

  Keyboard.addListener('keyboardDidHide', () => {
    document.body.classList.remove('keyboard-open');
  }).catch(err => {
    console.error('Keyboard.addListener error', err);
  });
}
