import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { CssBaseline, useMediaQuery, Snackbar, Button, Box, CircularProgress, NoSsr } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useApolloClient, useMutation } from '@apollo/client';
import { subMinutes } from 'date-fns';
import { SnackbarProvider } from 'notistack';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { SendIntent } from "send-intent";

import { createTheme } from './theme';
import { Keychain, UserDefaults, TestFlight } from './capacitor-plugins';
import { validateUrl } from './utils/url';

import { useAnalyticsSetup, useAnalyticsQueued } from './hooks/delicious-analytics';
import { useSession } from './hooks/auth';
import { useWorkbox } from './hooks/workbox';
import { useShouldUpdate } from './hooks/should-update';
import { useDeepLinks } from './hooks/deep-links';
import { useRemoveParams, StringParam } from './hooks/remove-params';
import { usePushNotificationEvents, usePushNotifications } from './hooks/notifications';
import { useAppIsRestored } from './hooks/app-state';
import { useInitializeLocationCount, useInitializeLocationWithMeta, useOverrideBackButton, useReferrer } from './hooks/history';
import { useInitializeWatchStates } from './hooks/watch-state';

import { GET_USER_DATA } from './queries/user';
import { UPDATE_NOTIFICATION } from './queries/notifications';
import { GET_FEED } from './queries/feed';

import BottomNav from './components/BottomNav';
import { LoadingIceCream } from './components/LoadingIceCream';
import { ErrorBoundary } from './components/ErrorBoundary';
import { ProcessFollowLink } from './components/ProcessFollowLink';
import { Routes } from './Routes';
import { NotLoggedIn } from './components/NotLoggedIn';
import { AppTrackingPrompt } from './components/AppTrackingPrompt';
import { HelmetReset } from './components/HelmetReset';


// Hoisted as suggested in https://mui.com/material-ui/customization/how-to-customize/
const cssBaseline = <CssBaseline />


function App() {

  useAnalyticsSetup();
  const { track, identify } = useAnalyticsQueued();

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const colorSchemeTheme = useMemo(() => {
    if (prefersDarkMode) {
      track(`prefers_color_scheme_${prefersDarkMode ? 'dark' : 'light'}`, { category: 'system'});
    }
    return createTheme(prefersDarkMode ? 'dark' : 'light');
    // return createTheme('light'); // force light mode for now
  }, [ prefersDarkMode, track ]);
  if (Capacitor.getPlatform() === 'ios') {
    document.body.classList.add('ios-status-bar');
  }

  const history = useHistory();
  useInitializeLocationWithMeta();
  useInitializeLocationCount();

  useOverrideBackButton();

  useEffect(() => {
    setTimeout(() => {
      window.IS_HYDRATING = false;
    }, 1000);
  }, []);

  const [ removableParams, removeParams ] = useRemoveParams({
    'token': StringParam,
    'notification': StringParam,
  });

  const [ deepLink, setDeepLink ] = useDeepLinks();
  useEffect(() => {
    if (deepLink) {
      try {
        history.replace(deepLink);
      } catch(e) {
        console.error(`Got exception navigating to deep-link, resetting to home. link:[${deepLink}] error:[${e}]`)
        history.replace('/');
      }
      setDeepLink(null);
    }
  }, [ deepLink, setDeepLink, history ]);

  useEffect(() => {
    if (Capacitor.getPlatform() === 'android') {
      const checkIntent = async () => {
        try {
          const result = await SendIntent.checkSendIntentReceived();
          if (result.url || result.text) {
            const newSearch = new URLSearchParams();
            if (result.title) {
              if(validateUrl(result.title)) {
                newSearch.set('share-url', result.title);
              } else {
                newSearch.set('share-message', result.title);
              }
            }
            if (result.text) {
              if(validateUrl(result.text)) {
                newSearch.set('share-url', result.text);
              } else {
                newSearch.set('share-message', result.text);
              }
            }
            if (result.url) {
              newSearch.set('share-url', result.url);
            }
            history.replace(`/share?${newSearch}`);
          }
        } catch(e) {
          console.error('SendIntent.checkSendIntentReceived', e);
        }
      };

      window.addEventListener("sendIntentReceived", checkIntent);
      checkIntent().catch(e => console.error('checkIntent error', e));

      return () => window.removeEventListener("sendIntentReceived", checkIntent);
    }
  }, [history]);


  const { received, action } = usePushNotificationEvents();
  useEffect(() => {
    if (received) {
      track('push_received', { category: 'push', origin: received.notification.data?.source });
      track('push', { type: 'received', category: 'push', origin: received.notification.data?.source });
    }
  }, [ received, track ]);
  useEffect(() => {
    if (action) {
      track(`push_${action.actionId}`, { category: 'push', origin: action.notification.data?.source });
      track('push', { type: 'action', action: action.actionId, category: 'push', origin: action.notification.data?.source });
    }
  }, [ action, track ]);

  const { initializing, auth, user, loading, error, tokenError, setTokenError } = useSession();

  useReferrer();

  const { waiting, acceptReload, dismissReload, dismissed } = useWorkbox();

  useInitializeWatchStates();

  const dataSentToNative = useRef(false);
  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios' && !dataSentToNative.current && auth && user) {
      dataSentToNative.current = true;
      Keychain.set({ key: 'refreshToken', value: auth.refreshToken }).then(({ success }) => {
        console.log('Keychain success:', success);
      }).catch(e => console.error('Keychain error', e));
      var groups = JSON.parse(JSON.stringify(user.groups));
      groups = user.groups.map( group => {
        return { ...group, members: group.members.map( ({ person }) => {
          return user.contacts.find( contact => contact._id === person?._id && contact.username && contact._id !== user._id);
        }).filter(Boolean)};
      });
      UserDefaults.set({ key: 'groups', value: JSON.stringify(groups) }).then(({ success }) => {
        console.log('UserDefaults success:', success);
      }).catch(e => console.error('UserDefaults error', e));
      UserDefaults.set({ key: 'contacts', value: JSON.stringify(user.contacts) }).then(({ success }) => {
        console.log('UserDefaults success:', success);
      }).catch(e => console.error('UserDefaults error', e));
    }
  }, [ auth, user ]);

  const [ shouldUpdate, showShouldUpdate ] = useShouldUpdate();
  const loadingShowed = useRef(false);
  useEffect(() => {
    if (shouldUpdate && !initializing && !loading && !loadingShowed.current) {
      loadingShowed.current = true;
      setTimeout(showShouldUpdate, 250);
    }
  }, [ shouldUpdate, showShouldUpdate, loading, initializing ]);

  const { isPushEnabled } = usePushNotifications();
  const isTestFlightChecked = useRef(false);
  useEffect(() => {
    if (user?._id && user?.email && isPushEnabled.enabled !== null) {
      identify(user._id, { pushEnabled: isPushEnabled.enabled, pushReason: isPushEnabled.reason });
      if (isPushEnabled.enabled) {
        track('push_enabled', {category: 'user-settings', reason: isPushEnabled.reason});
      } else if (isPushEnabled.enabled === false) {
        track('push_disabled', {category: 'user-settings', reason: isPushEnabled.reason});
      }
      if (Capacitor.getPlatform() === 'ios' && !isTestFlightChecked.current) {
        isTestFlightChecked.current = true;
        TestFlight.get().then(({ isTestFlight }) => {
          if (isTestFlight === true) {
            identify(user._id, { is_testflight: 1 });
          }
        }).catch(e => console.error('isTestFlight error', e));
      }
    }
  }, [ user, identify, isPushEnabled, track ]);

  useEffect(() => {
    if (!initializing) {
      // Initialize (ie firebase auth) is done but wait another 300ms for rendering
      setTimeout(() => {
        SplashScreen.hide();
        track('app_initialized', { category: 'performance' });
      }, 300);
    }
  }, [ initializing, track ]);


  const [updateNotification] = useMutation(UPDATE_NOTIFICATION);
  useEffect(() => {
    // wait with updating notification until auth is complete
    if(removableParams.notification && user && !removableParams.token && !loading) {
      track('clicked_notification', { deliveryId: removableParams.notification, category: 'notification' });
      updateNotification({ variables: { input: { id: removableParams.notification, clickedAt: new Date() } } })
      removeParams([ 'notification' ]);
    }
  }, [removableParams.notification, removeParams, user, updateNotification, removableParams.token, loading, track]);


  const client = useApolloClient();
  const latestFetchQueries = useRef(new Date());
  const onAppRestored = useCallback(() => {
    const isRecentlyUpdated = latestFetchQueries.current > subMinutes(new Date(), 5);
    if (client && user && !isRecentlyUpdated) {
      client.refetchQueries({
        include: [
          GET_USER_DATA,
          GET_FEED,
        ]
      });
      latestFetchQueries.current = new Date();
    }
  }, [client, user]);
  useAppIsRestored(onAppRestored);


  let loadingComponent = null;
  let errorComponent = null;

  // We only handle loading states in native because SSR will show public content directly
  if(Capacitor.isNativePlatform()) {
    if(initializing) {
      loadingComponent = <></>; // empty component
    }
    if(!user && loading) {
      loadingComponent = <LoadingIceCream message='Logging in' />;
    }
  }

  if(error) {
    errorComponent = (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100%",
          flexDirection: 'column',
        }}
        data-cy='error'
      >
        <Box sx={{ mb: 3, mx: 3 }}>
          Something went wrong fetching data from the Ice Cream Club server. Retrying...
        </Box>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={colorSchemeTheme}>
      <SnackbarProvider maxSnack={3}>
        {cssBaseline}
        <ErrorBoundary>
          <HelmetReset />

          <AppTrackingPrompt />

          {loadingComponent}
          {errorComponent}
          {!loadingComponent && !errorComponent && (
            <>
              <Box sx={{
                overflowX: 'hidden',
                width: '100%',
                height: '100%',
                backgroundColor: (theme) => theme.palette.grey[100],
                '& .MuiContainer-root': {
                  backgroundColor: 'background.paper',
                },
              }}>
                <Routes />
              </Box>

              <NoSsr>
                <NotLoggedIn />
              </NoSsr>

              {auth &&
                <BottomNav />
              }
              <Snackbar
                open={waiting && !dismissed}
                onClose={dismissReload}
                message={'Ice Cream Club has an update waiting'}
                action={
                  <Button color="primary" size="small" onClick={acceptReload}>
                    Reload
                  </Button>
                }
              />
              <Snackbar
                open={tokenError}
                onClose={() => { setTokenError(null) } }
                severity="warning"
                message={'Whoops! The login link you clicked has expired. You can ask for a new login email here, or use your password.'}
                action={
                  <Button color="primary" size="small" onClick={() => { setTokenError(null) } }>
                    Got it!
                  </Button>
                }
              />
              <ProcessFollowLink />
            </>
          )}
        </ErrorBoundary>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
export default App;
