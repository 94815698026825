
export const canonicalLink = (canonicalContentId?: string, shareId?: string) => {
  if(canonicalContentId && shareId) {
    return `/canonical/${canonicalContentId}/${shareId}`;
  } else if(canonicalContentId) {
    return `/canonical/${canonicalContentId}/`;
  } else if(shareId) {
    return `/post/${shareId}/`;
  }
  throw new Error('Invalid canonical link, need either canonicalContentId or shareId');
};

