import { gql } from '@apollo/client';
import { CanonicalFragment } from './canonicalFragment';
import { ListFragment } from './listFragment';
import { ShareCommentsFragment, ShareFragment } from './shareFragment';


export const CREATE_SHARE = gql`
  mutation CreateShare($input: CreateShareInput!) {
    createShare(input: $input) {
      _id
    }
  }
`;


export const ADD_REACTION = gql`
  mutation AddReaction($input: ReactionInput!) {
    addReaction(input: $input) {
      _id
      reactions {
        emoji {
          id
          name
          colons
          skin
          native
        }
        reacted {
          _id
        }
      }
    }
  }
`;


export const UNFURL = gql`
  mutation Unfurl($url: String!) {
    unfurl(url: $url) {
      title
      description
      link
      images {
        hash
        blurhash
        width
        height
        alt
      }
    }
  }
`;

export const SUGGEST_SHAREABLE = gql`
  query suggestShareable($text: String!) {
    searchShareable(text: $text) {
      results {
        title
        link
        description
      }
    }
  }
`;

export const SET_IN_LIST = gql`
  mutation SetInList($input: SetInListInput!) {
    setInList(input: $input) {
      ...ListFragment
      items {
        _id
        createdAt
        share {
          _id
        }
        canonicalContent {
          _id
        }
      }
    }
  }
  ${ListFragment}
`;


export const GET_SHARE = gql`
  query GetShare($id: ID!) {
    getShare(id: $id) {
      ...ShareFragment
      ...ShareCommentsFragment
      watchState {
        watchedBy
        watchlistBy
      }
      ratings {
        _id
        rating
        person {
          _id
        }
      }
      sendersRating {
        _id
        rating
        person {
          _id
        }
      }
    }
  }
  ${ShareFragment}
  ${ShareCommentsFragment}
`;


export const DELETE_SHARE = gql`
  mutation DeleteShare($id: ID!) {
    deleteShare(id: $id) {
      ...ShareFragment
    }
  }
  ${ShareFragment}
`;


export const ONBOARDING_SHARES = gql`
  query OnboardingShares {
    onboardingExampleShares {
      ...ShareFragment
      canonicalContent {
        ...CanonicalFragment
      }
    }
  }
  ${ShareFragment}
  ${CanonicalFragment}
`;
