import { gql } from '@apollo/client';
import { GroupFragment } from './groupFragment';
import { ShareCommentsFragment, ShareFragment } from './shareFragment';
import { CanonicalFragment } from './canonicalFragment';
import { CommentFragment } from './commentFragment';
import { AdFragment } from './adFragment';


export const FeedItemFragment = gql`
  fragment FeedItemFragment on FeedItem {
    _id
    createdAt
    format
    addedToList {
      _id
      name
      images {
        hash
        blurhash
        width
        height
        alt
      }
    }
    addedToListBy {
      _id
    }
    startedFollowing {
      addedBy {
        _id
      }
    }
    addedToGroup {
      addedBy {
        _id
      }
      group {
        ...GroupFragment
      }
    }
    addedWatchState {
      watchState {
        _id
        state
        comments {
          ...CommentFragment
        }
      }
      addedBy {
        _id
      }
    }
    newRating {
      rating {
        _id
        rating
      }
      addedBy {
        _id
      }
    }
    newSeason {
      seasonNumber
    }
    nowStreaming {
      provider {
        _id
        name
        color
        logo {
          hash
        }
      }
    }
    ad {
      ...AdFragment
    }
    share {
      ...ShareFragment
      ...ShareCommentsFragment
    }
    canonicalContent {
      ...CanonicalFragment
    }
  }
  ${ShareFragment}
  ${GroupFragment}
  ${CanonicalFragment}
  ${ShareCommentsFragment}
  ${CommentFragment}
  ${AdFragment}
`;
