import { gql } from '@apollo/client';


export const ContactFragment = gql`
  fragment ContactFragment on Contact {
    _id
    username
    name
    avatar {
      url
    }
    emailHashes
  }
`;
