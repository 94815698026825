
export const getProbableInAppBrowser = (userAgent: string) => {
  if(!userAgent) {
    return null;
  } else if (/(FB_IAB|FBAN|FBAV)/.test(userAgent)) {
    return 'facebook';
  } else if (/Instagram/.test(userAgent)) {
    return 'instagram';
  }
  return null;
};
