import { gql } from '@apollo/client';


export const ListFragment = gql`
  fragment ListFragment on List {
    _id
    name
    color
    description
    isPublic
    itemCount
    followerCount
    createdAt
    createdBy {
      _id
    }
    images {
      hash
      blurhash
      width
      height
      alt
    }
  }
`;
